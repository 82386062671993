import React from 'react';
import styles from './TopbarComponent.module.css';
import logo from '../../assets/SVG/Clinic Logo.png';
import calander from '../../assets/SVG/white calendar.svg';
import MuiBreadcrumbs from '../breadcrumbComponent';
import DefaultImageMale from '../../assets/SVG/Default Profile - Male.svg';
import { Avatar, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import UserSettingsComponent from '../userSettingsComponent';
import { DrawerComponent } from '../drawerComponent';
import { Clinic, User } from '../../types';
import { useNavigate } from 'react-router-dom';

interface TopBarProps {
  handleChange: (event: SelectChangeEvent) => void
  user: User
  clinic: string
}

const TopBarComponent: React.FC<TopBarProps> = ({handleChange, user, clinic}) => {

  const nevigate = useNavigate();

  return (
    <div className={styles.bar}>
      <div className={styles.drawer}>
        <DrawerComponent />
      </div>
      <div className={styles.logo_container}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.bd_container}>
        <MuiBreadcrumbs />
        <div className={styles.end}>
          <div>
            {user && <Typography variant="body1" color="textPrimary">{user.tenant?.name}</Typography>}
          </div>
          <div>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120, fontSize: '1rem' }}>
              <InputLabel id="demo-simple-select-filled-label">Clinic</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={clinic}
                onChange={handleChange}
              >
                {user?.clinic_department.map((clinic: Clinic) => (
                  <MenuItem key={clinic.clinic_id} value={clinic.clinic_id}>
                    {clinic.clinic__name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={styles.icons}>
            <div className={styles.icn_container}>
              <img onClick={() => {nevigate('/admin/appointments');}} src={calander} alt="calander" />
            </div>
          </div>
          <div className={styles.profile}>
            {user && <Avatar alt={user.username} src={user.photo_url || DefaultImageMale} />}
            <div>
              {user && <UserSettingsComponent user={user} />}
              {user && <Typography sx={{fontFamily: 'Nunito'}} variant="caption" color="textSecondary">{user.designation?.replace('_', ' ')}</Typography>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBarComponent;