import { Skeleton } from '@mui/material';
import React from 'react';

const DefaultLoader: React.FC = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: '100%', height: '100%'}}/>
    </div>
  );
};

export default DefaultLoader;