// convert local date time to backend
export function convertDateToISOWithOffset(date: Date): string {
  const timeZoneOffsetInMinutes = date.getTimezoneOffset();
  const localTime = new Date(date.getTime() - timeZoneOffsetInMinutes * 60000);
  
  // Format the date to ISO string without the Z
  const isoStringWithoutZ = localTime.toISOString().replace('Z', '');
  
  // Calculate the local time zone offset in hours and minutes
  const sign = timeZoneOffsetInMinutes > 0 ? '-' : '+';
  const hours = Math.floor(Math.abs(timeZoneOffsetInMinutes) / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (Math.abs(timeZoneOffsetInMinutes) % 60)
    .toString()
    .padStart(2, '0');
  // Return the ISO string with the local time zone offset
  return `${isoStringWithoutZ}${sign}${hours}:${minutes}`;
}

// convert backend date time to Local UI date time
export function convertToLocalDateTime(utcDateTimeString: string): Date {
  // Create a Date object from the UTC datetime string.
  const utcDate = new Date(utcDateTimeString);
  
  // Convert the UTC date to local time.
  const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
  
  return localDate;
}

export function formatDateToYYYYMMDD(date: Date): string {
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

export function convertISOToTime(isoString: string, isSuffix: boolean) {
  const date = new Date(isoString);

  // Get hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Determine AM or PM suffix
  const ampm = hours >= 12 ? 'pm' : 'am';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Return in "HH:MMam/pm" format
  if(isSuffix){
    return `${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
  } else {
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  }
}

export const formatDateToDisplay = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Get the local time zone abbreviation
  const timeZoneName = date.toLocaleTimeString('en-US', { timeZoneName: 'short' }).split(' ')[2];

  // Combine the date, time, and time zone for human readability
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${timeZoneName}`;
};

export function formatDateToDDMMYYYY(dateString: string | number | Date) {
  const date = new Date(dateString);
  
  // Get day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

