// src/types.ts
import { MbscCalendarEvent, MbscCalendarEventData } from '@mobiscroll/react';
import { Params } from 'react-router-dom';
// Define a type for the table column configuration
import * as Yup from 'yup';

export interface ColumnConfig {
  field: string;
  headerName: string;
  type: 'text' | 'select' | 'date' | 'int' | 'component';
  options?: string[]; // For select fields
  component?: JSX.Element; // For custom components
  editable?: boolean;
  showLabel?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCell?: (params: { value: string; row: any }) => JSX.Element;
  hideLabel?: boolean;
  required?: boolean; // Indicate if the field is required
  validationSchema?: Yup.AnySchema; // Custom validation schema for the field
  defaultValue?: string | number | null; // Default value for Formik
}

// Define a type for the action configuration
export type ActionType = 'select' | 'delete' | 'none';

// Define a type for the group configuration
export interface GroupConfig {
  fields: string[];
  type: 'row' | 'column';
  defaultHeaderGroupname?: string;
}

// Define a type for table rows
export interface TableRowData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  id: number;
  
}
// Define the PatientData interface
export interface PatientData {
  id: number;
  date: string;             
  MRN: string;              
  patientName: string;      
  gender: string;           
  age: string;           
  mobileNumber: string;    
  reasonOfVisit: string;     
  status: string | JSX.Element;    
  partnerName?: string;    
  partnerMRN?: string;  
  partnerage?: string; 
  partnergender?: string;  
  doctor?: string;   
  firstvisit?: string;

  // Optional fields for patient details
  first_name?: string; 
  last_name?: string;
  created_on?: string;
  middle_name?: string;
  gender__name?: string;
  date_of_birth?: string;
  medical_record_no?: string;
  mobile_number?: string;
  reason_for_visit?: string;

  // Partner information
  partner?: PartnerData; 
}



// Define the ApiResponse interface
export interface ApiResponse {
  error: boolean;
  msg: string;
  data: CountryData[];
  states?: string[]; 

}
export interface ApiResponseState {
  error: boolean;
  msg: string;
  data: CountryData;  
  states?: string[];
}

// Define the UserSettingsComponentProps interface
export interface UserSettingsComponentProps {
  user: User;
}

// Define possible button types for validation in ReusableCustombtn
export type ButtonType = 'button' | 'filter' | 'input' | 'radio' | 'date' | 'toggle';


// Define a type for the props of ReusableCustombtn
export interface ReusableCustombtnProps {
  type: ButtonType[];
  buttonName?: string;
  onClick?: () => void;
  isSelected?: boolean;
  radioOptions?: { name: string; value: string }[];
  onRadioChange?: (value: string) => void;
  selectedValue?: string;
  selectedButton?: string;
  formDate?: string;
  toDate?: string;
  onDateChange?: (startDate: string, endDate: string) => void;
  inputLabel?: string; 
  inputPlaceholder?: string; 
  dateFromLabel?: string;  
  dateToLabel?: string;  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInputChange?: (value: any) => void; 
}



// Define the interface for the LinkDelink component props

export interface LinkDelinkProps {
  title: string;
  onClose: () => void;
  patientId: string | null;
  partnerId?: string | null;
  patientName?: string| null;  
  partnerFirstName?: string| null; 
  partnerLastName?: string| null; 
}

// Define the interface for the data used in the LinkDelink component
export interface PartnerData {
  id: string | number | readonly string[] | undefined;
  mrNo: string;
  partnerName: string;
  age: number;
  gender: string;
  status?: string;
}

// Define the headers for the table
export type TableHeaders = ['', 'MR No.', 'Partner Name', 'Age', 'Sex Assigned At Birth'];

// Define the interface for the FilterPopup 
export interface FilterPopupProps {
  onClose: () => void;
}


// Define the interface for the MuiBreadcrumbs 
export interface BreadcrumbHandle {
  breadcrumb: string | ((params: Params<string>) => string);
}
// Define the interface for the AlertComponentProps 
export interface AlertComponentProps {
  error: string;
}
// Define the FormData interface
export interface FormData {
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  occupationIndustry: string;
  selectedValue: string;
  selectedBloodGroup: string;
  startDate: string;
  selectedMaritalStatus: string;
  selectedReferenceSource: string;
  additionalDetails: string;
  mobileNumber: string;
  officeNumber: string;
  emailId: string;
  address: string;
  country: string;
  city: string;
  state: string;
  areaCode: string;
  occupation: string;
  reasonOfVisit: string;
  ethnicity: string;
  preferredLanguage: string;
  Visiteddoctorbeforepregnancy: boolean;
  age: string;
  idType: string;
  id: string;
  selectedReferenceDetails: string;
  selectedReferenceDoctor: string;
  selectedRemark: string;
  selectedCompanyName: string;
  selectedSpecialRegistration: string;
  selectedEducation: string;
  selectedReasonOfVisit: string;
  selectedPreferredLanguageINR: string;
  selectedNationalityINR: string;
  selectedNOofYearsOfMarriageINR: string | null;
  selectedAlternateNumber: string;
  selectedLandmark: string;
  selectedAddress: string;
  selectedPinCode: string;
  selectedNoofexistingchildernINR: string;
  Permanent: boolean;
  Communication: boolean;
  addressINR: string;
  idhash:string;
  pincode:string;
  area:string;
  street:string;
  document:string;
  mobile_number_country_code:string;
  alternate_mobile_number_country_code:string
}

export interface loginDetails {
  username:string,
  password:string
}
export interface Gender {
  id: number;
  name: string;
}

export interface GenderResponse {
  meta: {
    limit: number;
    next: string | null;
    offset: number;
    previous: string | null;
    total_count: number;
  };
  objects: Gender[];
}


export interface AdditionalInfo {
  alternate_mobile_number: string;
  company_name: string;
  education: string;
  ethnicity: string;
  identity_proof_url: string;
  identity_no: string;
  identity_type: string;
  marital_status: string;
  nationality: string;
  no_of_existing_children_count: number | null;
  no_of_years_of_marriage: number | null;
  occupation: string;
  preferred_language: string;
  reason_for_visit: string;
  reference_details: string;
  remark: string;
  source_of_reference: string;
  special_registration: boolean;
  visited_doctor_before: boolean;
  visited_doctor_for_pregnancy_before: boolean;
}

export interface Address {
  address_type: string;
  area: string;
  city: string;
  country: string;
  landmark: string;
  line_1: string;
  line_2: string;
  pincode: string;
  state: string;
  street: string;
}

export interface Patient {
  created_on: string | number | Date;
  first_visit: string;
  partner: Partner; 
  doctor_name: string;
  partner_gender: string;
  partner_name: string;
  status: string;
  gender__name: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  date_of_birth: string;
  blood_group: string;
  gender: string;
  mobile_number: string;
  patient_type: string;
  photo_url: string;
  prefix: string;
  addresses: Address[];
  additional_info: AdditionalInfo;
}
export interface Partner {
  status: string;
  id: number;
  age: number;
  created_on: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  gender__name: string;
  medical_record_no: string;
  mobile_number: string;
  mobile_number_country_code: string;
}



export interface DonorFormData {
  prefix?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  sex: string;
  bloodgroup: string;
  dob: string;
  age: string;
  maritalstatus: string;
  idtype: string;
  id: string;
  selectedReferenceSource: string;
  reasonOfVisit: string;
  ethnicity: string;
  preferredLanguage: string;
  bodyType: string;
  eyeColor: string;
  handedness: string;
  height: string;
  weight: string;
  bmi: string;
  childHairColor: string;
  adultHairColor: string;
  shade: string;
  shadeType: string;
  fullness: string;
  texture: string;
  tone: string;
  condition: string;
  acne: boolean;  
  ageAcneOccurred: string;
  treatmentRequired: boolean;  
  mole: boolean;  
  freckles: boolean; 
  dimples: boolean; 
  vision: string;
  correction: string;
  eyeage: string;
  hearingDifficulties: boolean; 
  hearingdesc: string;
  devices: string;
  reasondevices: string;
  agedevices: string;
  mobile: string;
  address: string;
  country: string;
  state: string;
  city: string;
  area: string;
  pincode: string;
  everapplied: boolean;  
  donatedegg: boolean; 
  donorprogramname: string;
  donationcount: string;
  consultedwithyourfamily: boolean;  
}

export interface Patient {
  id: number;
  age: number;
  first_name: string;
  last_name: string;
  middle_name: string ; 
  date_of_birth: string;
  gender__name: string;
  medical_record_no: string;
  mobile_number: string;
  mobile_number_country_code: string;
  partner: Partner;
  reason_for_visit: string;
  status: string;
}
export interface PatientListResponse {
  meta: {
    limit: number;
    next: string | null;
    offset: number;
    previous: string | null;
    total_count: number;
  };
  objects: Patient[];
  
}

export interface PatientResponse {
  id: number;
  age: number;
  created_on: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  date_of_birth: string;
  gender__name: string;
  medical_record_no: string | null;
  mobile_number: string;
  mobile_number_country_code: string | null;
  partner: {
    id: number;
    created_on: string;
    last_name: string;
    middle_name: string;
    date_of_birth: string;
    gender__name: string;
    medical_record_no: string | null;
    mobile_number: string;
    mobile_number_country_code: string | null;
  };
  reason_for_visit: string | null;
}

export interface CountryData {
  name?: string;
  item: string;
  ISO2: string;
  iso2: string;
  iso3: string;
  country: string;
  states?: State[];  
  data: CountryData[], 
  cities: string[];
  
}
export interface CountryCode {
  code: string;
  name: string;
}


export interface State {
  name: string;      
  state_code: string;
}

export interface StateData {
  name: string;      
  iso2: string; 
}
declare module '@mui/material/styles' {
  interface Palette {
    vidaiOrange: string;
  }
  interface PaletteOptions {
    vidaiOrange: string;
  }
}

export interface AdditionalInfov2 {
  additional_details: string | null;
  alternate_email: string | null;
  alternate_mobile_number: string | null;
  alternate_mobile_number_country_code: string | null;
  company_name: string | null;
  consulted_with_family: boolean | null;
  education: string | null;
  ethnicity: string | null;
  identity_no: string | null;
  identity_proof_url: string | null;
  identity_proof_path: string | null;
  identity_type: string | null;
  marital_status: string | null;
  nationality: string | null;
  no_of_existing_children_count: number | null;
  no_of_years_of_marriage: string | null;
  occupation: string | null;
  patient: number | null;
  preferred_language: string | null;
  previous_doctor_name: string | null;
  previous_hospital_name: string | null;
  reason_for_visit: string | null;
  reference_details: string | null;
  referral_doctor: string | null;
  remark: string | null;
  source_of_reference: string | null;
  special_registration: boolean | null;
  visited_doctor_before: boolean | null;
  visited_doctor_for_pregnancy_before: boolean | null;
  acne: string | null;
  acne_age: string | null;
  age_used_dental: string | null;
  bmi: string | null;
  body_type: string | null;
  dental_device: string | null;
  dental_device_reason: string | null;
  describe_difficulty: string | null;
  dimples: string | null;
  donated_egg_or_sperm: boolean | null;
  donation_count_cycle: string | null;
  donor_program_name: string | null;
  ever_applied_screened: boolean | null;
  eye_color: string | null;
  freckles: string | null;
  hair_color_as_adult: string | null;
  hair_color_as_child: string | null;
  hair_fullness: string | null;
  hair_shade: string | null;
  hair_texture: string | null;
  hair_type: string | null;
  handedness: string | null;
  hearing_difficulties: boolean | null;
  height: string | null;
  moles: string | null;
  skin_condition: string | null;
  skin_tone: string | null;
  treatment_required: boolean | null;
  vision: string | null;
  vision_correction: string | null;
  vision_correction_age: string | null;
  weight: string | null;
}

export interface Addressv2 {
  address_type: string | null;
  area: string | null;
  city: string | null;
  country: string | null;
  landmark: string | null;
  line_1: string | null;
  line_2: string | null;
  pincode: string | null;
  state: string | null;
  street: string | null;
  user: number | null;
}

export interface Partnerv2 {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  date_of_birth: string;
  blood_group: string | null;
  gender: string;
  medical_record_no: string | null;
  mobile_number: string;
  mobile_number_country_code: string | null;
  patient_type: string | null;
  photo_url: string | null;
  photo_path: string | null;
  prefix: string | null;
  delink_reason: string | null;
  delink_remark: string | null;
  additional_info: AdditionalInfov2;
  addresses: Address[] | null;
}

export interface Patientv2 {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  date_of_birth: string;
  blood_group: string | null;
  gender: string;
  medical_record_no: string | null;
  mobile_number: string;
  mobile_number_country_code: string | null;
  patient_type: string | null;
  photo_url: string | null;
  photo_path: string | null;
  prefix: string | null;
  additional_info: AdditionalInfov2 | null;
  addresses: Addressv2[];
  partner: Partnerv2 | null;
  age: string
}

export interface BasicPatientInfo {
  first_name: string;
  last_name: string;
  photo_url: string | null;
  gender: string;
}

export interface Tenant {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  date_format: string;
  time_format: string;
  currency_symbol: string;
  currency_code: string;
  phone_number_format?: string | null; // Optional and can be null
  country_code?: string | null;        // Optional and can be null
  required_address_fields?: string | null; // Optional and can be null
  optional_address_fields?: string | null; // Optional and can be null
  region: string;
}

export interface Clinic {
  clinic_id: number,
  clinic__name: string,
  department_id: number,
  department__name: string
}

export interface User {
  id: number;
  designation?: string | null; // Optional and can be null
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  tenant: Tenant;
  clinic_department: Clinic[]
}

export enum FileTypes {
  PROFILE_PHOTO = 'PROFILE_PHOTO',
  IDENTITY_PROOF = 'IDENTITY_PROOF',
  DOCTOR_PROFILE_PHOTO = 'DOCTOR_PROFILE_PHOTO',
  DOCTOR_IDENTITY_PROOF = 'DOCTOR_IDENTITY_PROOF',
}

export interface File_Source_Req {
  file_name: string;
  source: FileTypes;
  ref_id: number;
}

export interface File_Source_Res {
  presigned_url: string
}

export enum Regions {
  US = 'US',
  IN = 'IN'
}

// Enum for Appointment Reasons
export enum AppointmentReasonEnum {
  PATIENT_INQUIRY = 'PATIENT_INQUIRY',
  INITIAL_CONSULTATION = 'INITIAL_CONSULTATION',
  TREATMENT_CONSULTATION = 'TREATMENT_CONSULTATION',
  FOLLOW_UP_CONSULTATION = 'FOLLOW_UP_CONSULTATION',
  IUI_P_WITH_PARTNER_SPERM = 'IUI_P_WITH_PARTNER_SPERM'
}

// Interface for Appointment
export interface Appointment {
  id: number,
  user: User; // User ID
  department__name: string; // Department ID
  department__id: number;
  appointment_date: string; // Date in YYYY-MM-DD format
  start_datetime: string; // Time in HH:MM format
  end_datetime: string; // Time in HH:MM format
  appointment_reason__reason: AppointmentReasonEnum; // Appointment reason as enum
  patient: Patientv2 | null; // Optional, can be null
  status: AppointmentStatus;
  checked_in_time: string;
  checked_out_time: string;
  appointment_type: string;
  remark: string;
}

export enum AppointmentStatus {
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  UNATTENDED = 'UNATTENDED',
  CHECKEDIN = 'CHECKED_IN',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
}

export interface Meta {
  limit: number,
  next?: string,
  offset: number,
  previous?: string,
  total_count: number
}

export interface AppointmentResponse {
  meta: Meta
  objects: [Appointment]
}
export interface AppointmentList {
  appointment_date: string | number | Date;
  id: string;
  gender: string;
  age: string;
  reasonOfVisit: string;
  start_time: string;
  end_time: string;
  first_name: string;
  medical_record_no: string;
  mobile_number: string;
}


export interface AppointmentReasonsResponse {
  meta: {
    limit: number;
    next: string | null;
    offset: number;
    previous: string | null;
    total_count: number;
  };
  objects: AppointmentReasons[]; 
}

export interface AppointmentReasons {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  default_duration: any;
  id: number; 
  reason: string;
}

export interface userListRoleWise {
  id: number;
  first_name: string; 
  last_name: string;
  user?:number
}

export interface userListWorkingHours {
  id: number;
  first_name: string; 
  last_name: string;
  user:number;
  department__name: string;
  end_date: string;
  end_time: string;
  start_date: string;
  start_time: string;
  username: string;
}

export interface AppointmentFormErrorsType {
  department?: string;       
  personnel?: string;        
  appointmentReason?: string;
  appointmentDate?: '';             
  timeFrom?: string;          
  timeTo?: string; 
  room?: string | undefined;
  table?: string | undefined;
  [key: string]: string | undefined; 
}


export interface NewPatientAppointmentFormErrorsType {
  department?: string;        
  personnel?: string;
  appointmentReason?: string;
  appointmentDate?: string;  
  timeFrom?: string;   
  timeTo?: string;      
  firstName?: string;  
  lastName?: string; 
  dob?: string;     
  emailId?: string;         
}

export  interface VisitLog {
  date: string;
  name: string; // Added Name field
  department: string; // Added Department field
  reasonOfVisit: string; // Added Reason of Visit field
  remarks: string; // Added Remarks field
}

export interface bookSlotPatientData {
  firstName: string;
  lastName: string;
  gender: string;
  mobile: string;
  dob: string; // Use Date type if you want to handle it as a date object
  email: string;
  mobile_country_code?: string; 
}

export interface bookSlotPartnerData extends bookSlotPatientData {
  isPartnerSelected: boolean;
}



export interface AppointmentData {
  id: number;
  gender: string;
  age: string;
  reasonOfVisit: string;
  date: string;
  TimeFrom: string;
  TimeTo: string;
  patientName: string;
  MRN: string;
  mobileNumber: string;
  doctor: string;
  firstvisit: string;
  status: JSX.Element;
  appointmentDate: string;
  appointmentReason: string;
  department: string;
  startTime: string;
  endTime: string;
  patient: string;
}

export interface CustomMbscEventData extends MbscCalendarEventData {
  status: AppointmentStatus
}

export interface CustomMbscEvent extends MbscCalendarEvent {
  status: AppointmentStatus
}


export interface Department {
  id: number; 
  name: string;
  resource?: string;
}

export interface AppointmentModalProps {
  resource?: string;
  open: boolean;
  onClose: () => void;
  slotDate?: Date  | string;  
  slotDepartment?: number | string;
  departmentName?: string;
}

export interface DepartmentDetail {
  id: number;
  name: string;
  clinic__name: string;
  tenant__name: string;
}

export interface DepartmentResponse {
  meta: Meta;
  objects: DepartmentDetail[];
}

export interface UserBasicResponse {
  meta: Meta;
  objects: UserBasic[];
}

export interface UserBasic {
  id: number;
  email: string;
  first_name: string;
  gender__name: string;
  last_name: string;
  username: string;
  role: string
}

export interface CreateInvetigation{
  patient?: number;
  laboratory_test?: number;
  remark?: string;
}

export interface Investigation{
  id?: number;
  patient: number;
  laboratory_test: number;
  remark: string;
}


export interface PaginatedInvestigationList {
  meta: MetaData;
  objects: TestObject[];
}

interface MetaData {
  limit: number;
  next: string | null;
  offset: number;
  previous: string | null;
  total_count: number;
}

interface TestObject {
  id: number;
  patient: patientDetails;
  laboratory_test: LaboratoryTest;
  remark: string;
}

interface patientDetails {
  id: number;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  gender: string;
}

interface LaboratoryTest {
  id: number;
  identifier: number;
  name: string;
  icon_url: string;
}


export interface Complaint {
  presenting_complaints: string;
  modality: string;
  duration: string;
  alleviating_factors: string;
  aggregating_factors: string;
  comments: string;
  onset: string;
}

export interface PastIllnessObject {
  id: number;
  patient: number;
   last_menstrual_date?: string;
  case_summary: string;
  complaints: Complaint[];
  follow_up_notes: string;
  follow_up_reason: string;
  follow_up_date: string;
}

export interface PastIllnessResponse {
  meta: {
    limit: number;
    next: string | null;
    offset: number;
    previous: string | null;
    total_count: number;
  };
  objects: PastIllnessObject[];
}

export interface TableRowDataHPI {
  id: number;
  Date: string; 
  last_menstrual_date? : string; 
  case_summary : string; 
  follow_up_notes : string; 
  follow_up_reason ?: string; 
  follow_up_date ?: string; 
  
}




export interface HPICComplaint {
  id: number;           
  Date: string;         
  last_menstrual_date?: string;      
  case_summary: string;  
  follow_up_notes: string;
  follow_up_reason?: string; 
  follow_up_date?: string;   
  system?: string | null;              
  presenting_complaints?: string | null; 
  onset?: string | null;               
  duration?: string | null;            
  modality?: string | null;            
  alleviating_factors?: string | null;  
  aggregating_factors?: string | null;  
}

export const initialData: HPICComplaint[] = [
  {
    id: 1,
    Date: '',
    last_menstrual_date: '',
    case_summary: '',
    follow_up_notes: '',
    follow_up_reason: '', 
    follow_up_date: '',
    system: '',
    presenting_complaints: '',
    onset: '',
    duration: '',
    modality: '',
    alleviating_factors: '',
    aggregating_factors: '',
  },
];


export interface ValidationErrors1 {
  firstName?: string;
  lastName?: string;
  gender?: string;
  mobile?: string;
  dob?: string;
  email?: string;
  mobile_country_code?:string;
  partnerFirstName?: string;
  partnerLastName?: string;
  partnerGender?: string;
  partnerMobile?: string;
  partnerDob?: string;
  partnerEmail?: string;  
  partner_mobile_country_code?:string;
}



export interface PaginatedDiagnosisMasterList {
  meta: MetaData;
  objects: DiagnosisMaster[];
}



export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}


export interface DiagnosisMaster {
  id?: number;           
  code: string;          
  name: string;       
  gender: GenderEnum;
}

export interface Doctor {
  id: string;  
  name: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  mobile?: string;
  dob?: string;
  email?: string;
}
