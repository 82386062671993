import React, {useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  FormControl,
  InputLabel,
  Pagination,
  PaginationItem,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { ColumnConfig, ActionType, GroupConfig, TableRowData } from '../../types';
import styles from './CustomTableComponent.module.css';
import { ConfirmationModal } from '../confirmationModal';

interface EditableTableProps {
  columns: ColumnConfig[];
  data: TableRowData[];
  actionType: ActionType;
  onAction?: (id: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (id: number, field: string, value: any) => void;
  groupConfig?: GroupConfig[];
  dataSize?: number; // Added dataSize prop to handle pagination logic
  onRowClick?: (id: number) => void;
}

const EditableTable: React.FC<EditableTableProps> = ({
  columns,
  data,
  actionType,
  onChange,
  onAction,
  groupConfig,
  dataSize,
  onRowClick
}) => {
  
  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '10px', 
          },
        },
      },
    },
  });


  const rowsPerPage = 10; // Number of rows per page
  const [page, setPage] = useState<number>(0); // State to manage the current page
  const [tableData, setTableData] = useState<TableRowData[]>(data);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null); 

  const totalPages = dataSize ? Math.ceil(dataSize / rowsPerPage) : 0; // Calculate total pages based on data size and rows per page
  const paginatedData = dataSize
    ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : tableData;
  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1); // Update current page state when pagination changes
  };


  const handleInputChange = (
    id: number,
    field: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      )
    );
    onChange?.(id, field, value);
  };

  const renderCell = (
    row: TableRowData,
    column: ColumnConfig
  ) => {
    const value = row[column.field];

    if (!column.editable) {
      if (column.type === 'text' || column.type === 'int') {
        if (column.showLabel && !column.hideLabel) {
          return <span>{column.headerName} : {value}</span>;
        } else {
          return <div className={styles.tb_main_text}>{value}</div>;
        }
      }
      if (column.type === 'component' && React.isValidElement(value)) {
        return value;
      }
      if (column.showLabel  && !column.hideLabel) {
        return <span>{column.headerName} : {value}</span>;
      }
      else {
        return <div className={styles.tb_main_text}>{value}</div>;
      }
    }

    const renderWithLabel = (inputElement: React.ReactNode) => (
      <div className={styles.tb_main_text}>
        {inputElement}
      </div>
    );


    switch (column.type) {
    case 'text':
    case 'int':
      return renderWithLabel(
        <TextField
          sx={{ borderRadius: '12px', '& .MuiOutlinedInput-root': { borderRadius: '12px' } }}
          variant="outlined"
          type={column.type === 'int' ? 'number' : 'text'}
          value={value}
          label={column.hideLabel ? undefined : column.headerName}
          onChange={(e) =>
            handleInputChange(row.id, column.field, e.target.value)
          }
        />
      );
    case 'select':
      return renderWithLabel(
        <FormControl>
          {/* Only render InputLabel if hideLabel is false */}
          {!column.hideLabel && (
            <InputLabel id={`select-${column.field}`} shrink={true}>
              {column.headerName}
            </InputLabel>
          )}
          <Select
            sx={{
              borderRadius: '12px',
              minWidth: '10rem',
              '& .MuiOutlinedInput-root': { borderRadius: '12px' }
            }}
            value={value}
            onChange={(e) =>
              handleInputChange(row.id, column.field, e.target.value)
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '20rem',
                  overflowY: 'auto',
                },
              },
            }}
            label={column.hideLabel ? undefined : column.headerName} // Ensure the label is also set correctly here
          >
            {column.options?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      
    case 'date':
      return renderWithLabel(
        <TextField
          className={styles.tb_main_text}
          sx={{ borderRadius: '12px', '& .MuiOutlinedInput-root': { borderRadius: '12px' } }}
          label={column.hideLabel ? undefined : column.headerName}
          variant="outlined"
          type="date"
          value={value}
          onChange={(e) =>
            handleInputChange(row.id, column.field, e.target.value)
          }
        />
      );
    case 'component':
      return renderWithLabel(React.isValidElement(value) ? value : null);
    default:
      return null;
    }
  };

  const renderActionCell = (rowId: number) => {
    if (actionType === 'select') {
      return <Checkbox />;
    }
    if (actionType === 'delete') {
      return (
        <IconButton
          sx={{ color: 'red' }}
          onClick={() => {
            setDeleteId(rowId); 
            setOpenModal(true); 
          }}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
    return null;
  };
  const handleConfirmDelete = () => {
    if (deleteId !== null) {
      onAction?.(deleteId); 
      setDeleteId(null); 
      setOpenModal(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false); 
    setDeleteId(null); 
  };

  const renderGroupedCells = (
    row: TableRowData,
    group: GroupConfig
  ) => {

    const groupedContent = group.fields.map((field) =>
      renderCell(row, columns.find((col) => col.field === field)!)
    );

    if (group.type === 'row') {
      return (
        <TableCell colSpan={group.fields.length}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {groupedContent.map((content, index) => (
              <span key={index}>{content}</span>
            ))}
          </div>
        </TableCell>
      );
    }

    if (group.type === 'column') {
      return (
        <TableCell>
          <div style={{ display: 'flex', flexDirection: 'column',  justifyContent: 'space-evenly', minHeight: '50px', padding: '0px'}}>
            {groupedContent.map((content, index) => (
              <div key={index}>{content}</div>
            ))}
          </div>
        </TableCell>
      );
    }

    return null;
  };
  useEffect(() => {
    setTableData(data);
    setPage(0);
  }, [data]);


  return (
    <ThemeProvider theme={theme}>
      <div>
        <TableContainer>
          <Table className={styles.custom_table}>
            <TableHead>
              <TableRow className={styles.custom_table_head}>
                {actionType !== 'none' && <TableCell sx={{ width: '20px' }}></TableCell>}
                {groupConfig ? (
                  groupConfig.map((group, index) => (
                    <TableCell key={index}>
                      {group.defaultHeaderGroupname? group.defaultHeaderGroupname: group.fields.map(field => columns.find(col => col.field === field)?.headerName).join(' | ')}
                    </TableCell>
                  ))
                ) : (
                  columns.map((column) => (
                    <TableCell key={column.field}>{column.headerName}</TableCell>
                  ))
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row) => (
                <TableRow key={row.id} className={styles.custom_table_body} onClick={() => {onRowClick?.(row.id);}}>
                  {actionType !== 'none' && (
                    <TableCell >{renderActionCell(row.id)}</TableCell>
                  )}
                  {groupConfig ? (
                    groupConfig.map((group, index) => (
                      <React.Fragment key={index}>
                        {renderGroupedCells(row, group)}
                      </React.Fragment>
                    ))
                  ) : (
                    columns.map((column) => (
                      <TableCell key={column.field}>{renderCell(row, column)}</TableCell>
                    ))
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>



        {(dataSize ?? 0) > 0 && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
            {/* Display range of rows and total entries */}
            {dataSize === null || dataSize === 0 ? (
              <p>Showing 0 entries</p>
            ) : (
              <p>Showing {page * rowsPerPage + 1} to {Math.min((page + 1) * rowsPerPage, dataSize ?? 0)} of {dataSize} entries</p>
            )}
            {/* Pagination Component */}
            <Pagination
              count={totalPages} // Total number of pages
              page={page + 1}
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={1}
              shape="rounded"
              sx={{
                width: 'auto',
                '& .MuiPaginationItem-root': {
                  width: '40px',
                  height: '32px',
                  fontSize: '14px',
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: 'rgba(119, 119, 119, 1)',
                  color: 'white',
                },
              }}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    fontSize: '14px',
                    borderRadius: '12px',
                    border: 'none',
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(119, 119, 119, 1)',
                      color: 'white',
                    },
                  }}
                />
              )}
            />
          </div>
        )}
        {/* Confirmation Modal */}
        <ConfirmationModal
          open={openModal}
          handleClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          type="delete" 
          title="Delete"
          contentMessage={'Are you sure you want to delete this information?'} 
        />
      </div>
    </ThemeProvider>
  );
};

export default EditableTable;