/* eslint-disable no-console */
import React from 'react';


export const ExampleForm = () => {


  return (
    <div></div>
  );
};

export default ExampleForm;