/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
 
 
 
/* eslint-disable no-console */
import React, { Suspense, useMemo } from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Eventcalendar,
  MbscCalendarDayData,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscEventCreateEvent,
  MbscEventDragEvent,
  MbscResource,
  Segmented,
  SegmentedGroup,
  setOptions,
} from '@mobiscroll/react';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import './Calendar.css';
import { Avatar, Box, Button, ButtonGroup, Divider, FormControlLabel, IconButton, Menu, MenuItem, Radio, RadioGroup, Stack } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import HorizontalAvatarList from '../avatarListComponent';
import { departments, departmentsByClinic, getAppointments, getUsersByClinic, updateAppointmentDate } from '../../services/apiService';
import { Appointment, AppointmentResponse, CustomMbscEvent, DepartmentDetail, DepartmentResponse, UserBasic, UserBasicResponse } from '../../types';
import { useAlert } from '../alertProvider';
import { useLoader } from '../loaderProvider/loader';
import { ReactComponent as Verified } from '../../assets/SVG/Vuesax.svg';
import { ReactComponent as Delete } from '../../assets/SVG/unattanded.svg';
import { ReactComponent as NoShow } from '../../assets/SVG/noshow.svg';
import { ReactComponent as Clock } from '../../assets/SVG/clocka.svg';
import { ReactComponent as Checked } from '../../assets/SVG/checked.svg';
import Modal from '@mui/material/Modal';
import AppointmentTable from '../AppointmentTable/appointmentTable';
import '../../custom-mobiscroll-theme.scss';
import AppointmentModal from '../appointmentForm/appointmentForm';
import { EventcalendarBase } from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar';
import { convertToLocalDateTime } from '../../services/time';
import CustomModal from '../appointmentViewModal';
import { useTenant } from '../tenantProvider';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import de from '@mobiscroll/react/dist/src/i18n/de';
import OutOfOfficeForm from './outOfOfficeForm';
import { isDateInPast } from '../../utils/commonUtils';

// import AppointmentTable from '../AppointmentTable/appointmentTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
  

setOptions({
  theme: 'ios',
  themeVariant: 'light',
  accent: 'Orange'
});

const CalendarComponent: FC = () => {
  const [view, setView] = useState('month');
  const [resource, setResource] = useState<MbscResource[] | undefined>();
  const [myEvents, setEvents] = useState<MbscCalendarEvent[]>([]);
  const [calView, setCalView] = useState<MbscEventcalendarView | undefined>(undefined);
  const [menuPosition, setMenuPosition] = useState<{ mouseX: number; mouseY: number } | null>(null);
  const [clickedEvent, setClickedEvent] = useState<MbscCalendarEvent | null>(null);
  const [useTimeline, setUseTimeline] = React.useState(true);
  const [tabValue, setTabValue] = useState<DepartmentDetail>();
  const [currentView, setCurrentView] = useState<'CALENDAR' | 'TABLE'>('CALENDAR');
  const [appointments, setAppointments] = useState <[Appointment]  | undefined >();
  const [departmentList, setDepartmentList] = useState<DepartmentDetail[]>([]);
  const [showOutofOfficeForm, setShowOutofOfficeForm] =  useState<boolean>(false);
  const handleClosehowOutofOfficeFormModel = () => {
    setShowOutofOfficeForm(false);
    setModelStateUpdate(!modelStateUpdate);
  };
  const {addAlert} = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const { tenantId } = useTenant();

  
  const [openViewModel, setOpenViewModel] = React.useState(false);
  const handleOpenViewModel = () => setOpenViewModel(true);
  const handleCloseViewModel = () => {
    setOpenViewModel(false);
    setViewEvent(undefined);
    setModelStateUpdate(!modelStateUpdate);
  };
  const [viewEvent, setViewEvent] = React.useState<MbscEventClickEvent | undefined>(undefined); 

  const [openCreateModel, setOpenCreateModel] = React.useState(false);
  
  // const handleOpenCreateModel = () => setOpenCreateModel(true);
  /**
   * Opens the create event modal if the end date is in the future.
   * 
   * @param event - The event object containing start and end dates.
   * 
   * If the end date is in the past, an alert is shown, and the modal won't open.  
   **/
  const handleOpenCreateModel = (event: MbscEventCreateEvent) => {    
    const selectedDate = event.event.end as Date; 
    
    if (isDateInPast(selectedDate)) {
      setCreateEvent(undefined);

      setModelStateUpdate(!modelStateUpdate);
      addAlert('Oops! Please select a future date for book appointment.', 'info');
      return; 
    }
    setOpenCreateModel(true);
  };
  
  const handleCloseCreateModel = () => {
    setCreateEvent(undefined);
    setOpenCreateModel(false);
    setModelStateUpdate(!modelStateUpdate);
  };
  const [createEvent, setCreateEvent] = React.useState<MbscEventCreateEvent | undefined>(undefined);
  const [users, setUsers] = React.useState<UserBasic[] | undefined>(undefined);
  const [selectedDoctor, setSelectedDoctor] = React.useState<number | undefined>(undefined);
  const [query, setQuery] = React.useState<string |  undefined>(undefined);
  const [modelStateUpdate, setModelStateUpdate] = useState(false);
  const departmentFilters = ['Admin', 'Pathology', 'Semen Collection'];
  const docFilters = ['Consultation', 'Ultrasound', 'Surgery'];

  useEffect(() => {
    console.log('query',query);
  }, [query]);

  useEffect(() => {
    console.log('tenant', tenantId);
    if (tenantId) {
      departmentsByClinic(tenantId).then((response: DepartmentResponse) => setDepartmentList(response.objects))
        .catch(() => addAlert('Failed to fetch departments', 'error'));
    }
  }, [tenantId, addAlert]);


  useEffect(() => {
    setSelectedDoctor(undefined);
    console.log('tab', tabValue);
  }, [tabValue]);

  useEffect(() => {
    departmentList.map((department: DepartmentDetail) => {
      if (department.name === 'Admin') {
        setTabValue(department);
      }
      return department;
    });
  }, [departmentList]);

  useEffect(() => {
    if (tabValue) {
      const query = `limit=1000&order_by=end_datetime&department__id=${tabValue?.id}`;
      setQuery(query);
      console.log('dep',tabValue);
      getUsersByClinic(tenantId, tabValue?.id.toString()).then((userRes: UserBasicResponse) => setUsers(userRes.objects));
    }
    // eslint-disable-next-line
  }, [tabValue, tenantId]);

  useEffect(() => {
    if (selectedDoctor) {
      setQuery(`limit=1000&order_by=end_datetime&department__id=${tabValue?.id}&user__id=${selectedDoctor}`);
    } else {
      setQuery(`limit=1000&order_by=end_datetime&department__id=${tabValue?.id}`);
    }
    // eslint-disable-next-line
  }, [selectedDoctor]);

  useEffect(()=> {
    showLoader();
    const myResources: MbscResource[] | undefined = appointments?.map((appointment: Appointment) =>({
      id: appointment.user.id,
      name: `${appointment.user.first_name} ${appointment.user.last_name}`,
      photo: appointment.user.photo_url
    }));
    if (myResources) {
      const unoqueResources = Array.from(
        new Map(myResources.map((resource) => [resource.id, resource])).values()
      );
      setResource(unoqueResources);
    }
    hideLoader();
  }, [appointments, hideLoader, showLoader]);


  // Handle right-click event
  const handleEventRightClick = (args: MbscEventClickEvent) => {
    args.domEvent.preventDefault();  // Prevent the default context menu
    setClickedEvent(args.event);  // Set the clicked event
    // Set the menu position at the cursor location
    setMenuPosition({
      mouseX: args.domEvent.clientX,
      mouseY: args.domEvent.clientY
    });
  };

  // Close the Material-UI menu
  const closeMenu = () => {
    setMenuPosition(null);
  };

  useEffect(() => {
    showLoader();
    if (view === 'timeline') {
      setUseTimeline(true);
    } else {
      setUseTimeline(false);
    }
    hideLoader();
  }, [view, hideLoader, showLoader]);

  // Handle calendar view change
  const changeView = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    let calView = {};
    switch (event.target.value) {
    case 'month':
      calView = { calendar: { labels: true } };
      break;
    case 'week':
      calView = { schedule: { type: 'week', maxEventStack: 1 } };
      break;
    case 'day':
      calView = { schedule: { type: 'day', timeCellStep: 15, maxEventStack: 4 } };
      break;
    case 'timeline':
      calView = {
        timeline: {
          type: 'week',
          timeCellStep: 15,
        }
      };
      break;
    }
    setView(event.target.value);
    setCalView(calView);
  } , []);

  const avatars = useMemo(() => {
    return users?.map((user: UserBasic) => ({
      id: user.id,
      name: `${user.first_name}`,
      src: '',
      selected: selectedDoctor === user.id
    }));
  }, [users, selectedDoctor]);

  const stringToColor = (name: string) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `#${((hash >> 24) & 0xFF).toString(16)}${((hash >> 16) & 0xFF).toString(16)}${((hash >> 8) & 0xFF).toString(16)}`.slice(0, 7);
    return color;
  };

  const renderCustomResource = (resource: MbscResource) => (
    <div className='cal-res-ava'>
      {resource && resource.name && <Avatar
        alt={resource.name}
        src={resource.img}
        sx={{
          width: 50, 
          height: 50,
          ...(typeof(resource.id) === 'number' && {bgcolor: numberToColorHash(resource.id)}), // Background color if no image
          fontSize: '0.8rem' // Smaller font for the initials
        }}
      >
        {!resource.img && resource.name[0]} {/* Display first letter if no image */}
      </Avatar>  
      }
      <div className="resource-name">{resource.name}</div></div>
  );

  const renderEvent = (resource: MbscCalendarEventData) => (
    <div className='cal-event-label'>
      {resource.title?.split('--')[1] === 'CANCELLED'? `${resource.title?.split('--')[0]} CANCELLED`: resource.title?.split('--')[0]}
    </div>
  );

  const handleAvatarSelection = useCallback((id: number) => {
    console.log(id);
    if (id < 0 || id === selectedDoctor) {
      setSelectedDoctor(undefined);
      return;   
    }
    setSelectedDoctor(id);
  }, [selectedDoctor]);

  // Custom header with navigation buttons
  const customWithNavButtons = useCallback(
    () => (
      <div className='cal-container'>
        <div className='cal-ftr'>
          {!useTimeline && (tabValue?.name === 'Surgery' || tabValue?.name === 'Ultrasound' || tabValue?.name === 'Consultation') && avatars && <HorizontalAvatarList avatars={avatars} maxWidth={1000} onClick={handleAvatarSelection}/>}
        </div>
        <div className='cal-nav'>
          <CalendarNav className="cal-header-nav"/>
          <div className="cal-header-picker">
            <SegmentedGroup value={view} onChange={changeView}>
              <Segmented value="day">Day</Segmented>
              <Segmented value="week">Week</Segmented>
              <Segmented value="month">Month</Segmented>
              {(tabValue?.name === 'Consultation' || tabValue?.name === 'Surgery' || tabValue?.name === 'Ultrasound') && <Segmented value="timeline" className='cal-schedule'>Schedule</Segmented>}
            </SegmentedGroup>
          </div>
          <div>
            <CalendarPrev className="cal-header-prev" />
            <CalendarToday className="cal-header-today" />
            <CalendarNext className="cal-header-next" />
          </div>
          <div>
            {/* <Button variant='text' onClick={() => {setShowOutofOfficeForm(true);}}>Schedule Out of office</Button> */}
          </div>
        </div>
      </div>
    ),
    [changeView, view, avatars, useTimeline, tabValue, handleAvatarSelection],
  );

  

  const numberToColorHash = useCallback((num: number) => {
    // Use the golden ratio to spread out hue values more evenly
    const goldenRatio = 0.618033988749895; // Golden ratio conjugate
    const hue = (num * goldenRatio * 360) % 360; // Generate hue in the range [0, 360]
  
    // Convert hue to RGB using HSL model
     
    return hslToHex(hue, 65, 50); // 65% saturation, 50% lightness
  }, []);

  
  
  function hslToHex(h: number, s: number, l: number): string {
    s /= 100;
    l /= 100;
  
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = l - c / 2;
    let r = 0, g = 0, b = 0;
  
    if (0 <= h && h < 60) {
      r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
      r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
      r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
      r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
      r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
      r = c; g = 0; b = x;
    }
  
    // Convert r, g, b from 0–1 to 0–255 and then to hex
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);
  
    const redHex = r.toString(16).padStart(2, '0');
    const greenHex = g.toString(16).padStart(2, '0');
    const blueHex = b.toString(16).padStart(2, '0');
  
    return `#${redHex}${greenHex}${blueHex}`;
  }

  
  // Fetch calendar events from API
  useEffect(() => {
    showLoader();
    if (query && tabValue) {
      getAppointments(query).then((appointments: AppointmentResponse) => {
        setAppointments(appointments.objects);
        const events = appointments.objects
          .filter((appointment: Appointment) => appointment.status !== 'CANCELLED')
          .map((appointment: Appointment) => {
            const msbcEvent: CustomMbscEvent = {
              start: convertToLocalDateTime(appointment.start_datetime),
              end: convertToLocalDateTime(appointment.end_datetime),
              title: getEventText(appointment),
              id: appointment.id,
              color: numberToColorHash(appointment.user.id),
              resource: appointment.user.id,
              status: appointment.status,
              ...(appointment.status === 'CANCELLED'? {color: 'grey'}: '')
            };
            return msbcEvent;
          });
        setEvents(events);
        hideLoader();
      }).catch((error)=> {
        console.log(error);
        addAlert('Error while updating calendar', 'error');
      }).finally(() => hideLoader());
    }
  // eslint-disable-next-line
  }, [showLoader, hideLoader, addAlert, numberToColorHash, query, modelStateUpdate],);
  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  function handleRenderDay(args: MbscCalendarDayData) {
    return <div>{args.date.getDate().toString()}</div>;
  }

  function handleEnventDragEnd(args: MbscEventDragEvent, inst: EventcalendarBase): void {
    showLoader();
    const start = args.event.start as Date;
    const end = args.event.end as Date;
    const id = args.event.id;
    if (id) {
      updateAppointmentDate(id as number, start, end).then((res: any) => {
        addAlert('Schedule updated', 'success');
      }).catch((error: any) => {
        addAlert(error.response.data.errors[0].detail || 'error occured', 'error');
        console.log(error);
      })
        .finally(() => {
          hideLoader();
          setModelStateUpdate(!modelStateUpdate);
        });
    }
  }
  return (
    <>
      <OutOfOfficeForm open={showOutofOfficeForm} handleClose={handleClosehowOutofOfficeFormModel}/>
      <CustomModal 
        isOpen={openViewModel}
        onClose={handleCloseViewModel}
        id={viewEvent?.event.id as number}
      />
      <AppointmentModal open={openCreateModel} onClose={handleCloseCreateModel} slotDate={createEvent?.event.start as Date || undefined} slotDepartment={tabValue?.id} departmentName={tabValue?.name}/>
      <div className={'cal-tabs-sec'}>
        <div className='cal-deps'>
          {view !== 'timeline' &&
            <><ButtonGroup sx={{
              borderRadius: '2rem',
              height: 'fit-content',
              display: 'flex',
              gap: '5px', // Add gap between buttons
              '& .MuiButton-root': {
                border: 'none',
              },
              '& .MuiButton-root:hover': {
                backgroundColor: 'transparent', // Ensure hover doesn't add background color
              },
            }} variant="outlined" color='secondary' aria-label="Basic button group">
              {departmentList && departmentList.filter((department: DepartmentDetail) => departmentFilters.includes(department.name))
                .map((department: DepartmentDetail) => (<Button sx={{
                  color: tabValue?.id === department.id ? '#E17E61' : 'inherit',
                  fontFamily: 'nunito',
                  fontWeight: tabValue?.id === department.id ? 900 : 500,
                  fontSize: '1rem',
                  border: '1px solid black',
                  height: '2rem'
                }} key={department.id} onClick={() => { setTabValue(department); } }>{department.name}</Button>))}
            </ButtonGroup><Divider orientation="vertical" flexItem /></>
          }
          <ButtonGroup sx={{
            borderRadius: '2rem',
            height: 'fit-content',
            display: 'flex',
            gap: '5px', // Add gap between buttons
            '& .MuiButton-root': {
              border: 'none',
            },
            '& .MuiButton-root:hover': {
              backgroundColor: 'transparent', // Ensure hover doesn't add background color
            },
          }} variant="outlined" color='secondary' aria-label="Basic button group">
            {departmentList && departmentList.filter((department: DepartmentDetail) => docFilters.includes(department.name))
              .map((department: DepartmentDetail) => (<Button sx={{
                color: tabValue?.id===department.id ? '#E17E61' : 'inherit',
                fontFamily: 'nunito',
                fontWeight: tabValue?.id===department.id ? 900: 500,
                fontSize: '1rem',
                border: '1px solid black',
                height: '2rem'
              }} key={department.id} onClick={()=>{setTabValue(department);}}>{department.name}</Button>))}
          </ButtonGroup>
        </div>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center',  }}>
          <IconButton disabled={currentView==='CALENDAR'} onClick={()=>{setCurrentView('CALENDAR');}}><CalendarViewMonthIcon/></IconButton>
          <IconButton disabled={currentView==='TABLE'} onClick={()=>{setCurrentView('TABLE');}}><ListIcon/></IconButton>
        </Stack>
      </div>
      {currentView === 'CALENDAR' ? <div onClick={closeMenu} onContextMenu={handleContextMenu} className='table-parent'>
        <Suspense fallback={<CalendarComponent/>}>
          <Eventcalendar
            renderScheduleEventContent={renderEvent}
            renderLabelContent={renderEvent}
            clickToCreate={true}
            dragToCreate={false}
            dragToMove={true}
            dragToResize={false}
            eventDelete={true}
            renderHeader={customWithNavButtons}
            height={'87vh'}
            width={'100%'}
            view={calView}
            data={myEvents}
            cssClass="md-switching-view-cont"
            onEventRightClick={handleEventRightClick}
            onEventDragEnd={handleEnventDragEnd}
            {...(useTimeline && { resources: resource })}
            {...(useTimeline && { renderResource: renderCustomResource })}
            onEventCreate={(event: MbscEventCreateEvent) => {
              setCreateEvent(event);
              handleOpenCreateModel(event);
            } }
            onEventClick={(event: MbscEventClickEvent) => {
              setViewEvent(event);
              handleOpenViewModel();
            } } 
            // Disable or grey out past dates so users can't book slots for previous dates
            invalid={[
              {
                start: new Date(0),                        // Start from the earliest date possible
                end: new Date(new Date().setDate(new Date().getDate() )),  // Disable up to yesterday
              }
            ]}
          />
        </Suspense>

        {/* Material-UI Dropdown Menu */}
        <Menu
          open={Boolean(menuPosition)}
          onClose={closeMenu}
          anchorReference="anchorPosition" // Use anchorPosition to position based on mouse click
          anchorPosition={menuPosition ? { top: menuPosition.mouseY, left: menuPosition.mouseX } : undefined} // Menu opens at the cursor
        >
          <MenuItem onClick={() => { console.log('Edit', clickedEvent); closeMenu(); } }>Edit Event</MenuItem>
          <MenuItem onClick={() => { console.log('Delete', clickedEvent); closeMenu(); } }>Delete Event</MenuItem>
        </Menu>
      </div>:   <AppointmentTable departmentID={tabValue?.id} departmentName={tabValue?.name}/>}
    </>  
  );
};
export default CalendarComponent;

function getEventText(appointment: Appointment): string {
  if (appointment.department__name.toUpperCase() === 'ADMIN') {
    return `Pt-${appointment.patient?.first_name} Ad-${appointment.user.first_name}--${appointment.status}`;
  }
  if (appointment.department__name.toUpperCase() === 'PATHOLOGY') {
    return `Pt-${appointment.patient?.first_name} Pb-${appointment.user.first_name}--${appointment.status}`;
  }
  if (appointment.department__name.toUpperCase() === 'SEMEN COLLECTION') {
    return `Pt-${appointment.patient?.first_name}--${appointment.status}`;
  }
  return `Pt-${appointment.patient?.first_name} Dr-${appointment.user.first_name}--${appointment.status}`;
}
 
function getIcon(status: string): React.ReactNode {
  if(status==='SCHEDULED') {
    return <Checked/>;
  }
  if (status==='COMPLETED') {
    return <Verified/>;
  }
  if (status==='CANCELLED') {
    return <Delete/>;
  }
  return <Checked/>;
}

