/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { TextField, Grid } from '@mui/material';
import addCircle from '../../assets/SVG/add-black.svg';
import styles from './DiagnosisHome.module.css';
import EditableTable from '../customTableComponent';
import { ColumnConfig, GroupConfig, TableRowData } from '../../types';
import { usePatientId } from '../patientIdcontext';
import InfoIcon from '@mui/icons-material/Info';
import AddDiagnosis from './AddDiagnosis'; 


const columns: ColumnConfig[] = [
  { field: 'date', headerName: 'Date', type: 'text', editable: false },
  { field: 'code', headerName: 'Code', type: 'text', editable: false },
  { field: 'name', headerName: 'Name', type: 'text', editable: false },
  { field: 'type', headerName: 'Type', type: 'text', editable: false },
  { field: 'doctor', headerName: 'Doctor', type: 'text', editable: false },
  { field: 'notes', headerName: 'Notes', type: 'text', editable: false },
];

const groupConfig: GroupConfig[] = [
  { fields: ['date'], type: 'column' },  
  { fields: ['code'], type: 'column' },  
  { fields: ['name'], type: 'column' },  
  { fields: ['type'], type: 'column' },  
  { fields: ['doctor'], type: 'column' }, 
  { fields: ['notes'], type: 'column' },  
];

const dummyData: TableRowData[] = [
  { id: 1, date: '2024-10-01', code: 'A01.1', name: 'John Doe', type: 'Diagnosis', doctor: 'Dr. Smith', notes: <><InfoIcon fontSize="small" /> Patient stable.</> },
  { id: 2, date: '2024-10-02', code: 'B02.2', name: 'Jane Doe', type: 'Follow-up', doctor: 'Dr. Jones', notes: <><InfoIcon fontSize="small" /> Needs further testing.</> },
  { id: 3, date: '2024-10-03', code: 'C03.3', name: 'Bob Brown', type: 'Consultation', doctor: 'Dr. Taylor', notes: <><InfoIcon fontSize="small" /> Recommended physical therapy.</> },
];

const DiagnosisHome: React.FC<{ onAddClick: () => void }> = ({ onAddClick }) => {
  const [data, setData] = useState<TableRowData[]>([]);
  const [dataSize] = useState<number>(dummyData.length); 
  const [showAddDiagnosis, setShowAddDiagnosis] = useState<boolean>(false); 
  const patientId = usePatientId();

  useEffect(() => {
    setData(dummyData);


    
  }, [patientId]);

  const handleOnChange = (id: number, field: string, value: string) => {
    console.log('On Change:', { id, field, value });
  };

  const handleAction = (id: number) => {
    console.log('Action Triggered for ID:', id);
  };

  const handleAddClick = () => {
    setShowAddDiagnosis(true); 
    onAddClick();
  };

  const handleBackClick = () => {
    setShowAddDiagnosis(false); 
  };

  return (
    <div className={`${styles.home} mt-4`} style={{ display: 'flex', flexDirection: 'column' }}>
      {showAddDiagnosis ? (
        <AddDiagnosis onBackClick={handleBackClick} />
      ) : (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
              <TextField id="outlined-basic" label="Search "  variant="outlined" fullWidth />
            </Grid>
            <Grid item xs />
            <Grid item xs={12} sm="auto">

              <button onClick={handleAddClick} style={  {padding: '5px 25px', border: 'none', borderRadius: '7px', fontSize:'larger', fontWeight:'700', backgroundColor:'#F3F3F3F3', color:'#505050' }}>
                <img style={{padding:'5px', width:'40px', height:'40px'}} src={addCircle} alt='add'/>   Add
              </button>
            </Grid>
          </Grid>

          <div className="mt-4" style={{ flexGrow: 1 }}>
            <EditableTable
              columns={columns}
              data={data}
              actionType="none"
              onAction={handleAction}
              onChange={handleOnChange}
              groupConfig={groupConfig} 
              dataSize={dataSize}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DiagnosisHome;
