/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Modal, Box, Typography, TextField, Button, FormHelperText } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import './Calendar.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { bookOutOfOffice, getMyProfile } from '../../services/apiService';
import { useLoader } from '../loaderProvider/loader';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import { DateRange, OutOfOfficeRequest, splitDateRangeIntoDailyGroups } from './Calendar.helper';
import { convertDateToISOWithOffset, formatDateToYYYYMMDD } from '../../services/time';
import { User } from '../../types';
import { useAlert } from '../alertProvider';
import { AxiosError } from 'axios';

interface OutOfOfficeFormProps {
  open: boolean;
  handleClose: () => void;
}

const OutOfOfficeForm: FC<OutOfOfficeFormProps> = ({ open, handleClose }) => {
  const [user, setUser] = useState<User | undefined>();
  const { showLoader, hideLoader } = useLoader();
  const { addAlert } = useAlert();

  useEffect(() => {
    showLoader();
    getMyProfile()
      .then((response: User) => setUser(response))
      .catch((error) => console.error(error))
      .finally(() => hideLoader());
  }, [hideLoader, showLoader]);

  const formik = useFormik({
    initialValues: {
      start_datetime: '' as Date | string,
      end_datetime: '' as Date | string,
      reason: ''
    },
    validationSchema: Yup.object({
      start_datetime: Yup.date().required('Start date and time is required').typeError('Invalid datetime format'),
      end_datetime: Yup.date()
        .required('End date and time is required')
        .min(Yup.ref('start_datetime'), 'End datetime cannot be before start datetime')
        .typeError('Invalid datetime format'),
      reason: Yup.string().required('Reason is required')
    }),
    onSubmit: async (values) => {
      if (user) {
        const dates = splitDateRangeIntoDailyGroups(values.start_datetime as Date, values.end_datetime as Date);
        dates.forEach((date: DateRange) => {
          const request: OutOfOfficeRequest = {
            user: user.id,
            start_time: convertDateToISOWithOffset(date.start),
            end_time: convertDateToISOWithOffset(date.end),
            start_date: formatDateToYYYYMMDD(date.start),
            end_date: formatDateToYYYYMMDD(date.end),
            remarks: values.reason,
            is_available: false
          };
          console.log(request);
          // bookOutOfOffice(request).catch((error: AxiosError) => addAlert(error.response?.statusText || 'Error occurred', 'error')).finally(() => hideLoader());
        });
      }
    }
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box className="cal-ooo-modal">
        <div className='cal-ooo-heading'>
          <Typography id="modal-modal-title" variant="h5" component="h3" fontFamily={'nunito'} fontWeight={700} color='#E17E61'>
            <WorkOffIcon/> Mark Out of Office
          </Typography>
        </div>
        <div className="cal-ooo-form" >
          <Typography id="modal-modal-title" variant="h4" component="h2" fontFamily={'nunito'} color='#747373'>
            <label>{`${user?.first_name} ${user?.last_name}`}</label>
          </Typography>
          <div className='cal-ooo-secinfo'>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontFamily={'nunito'} fontWeight={700} color='#747373'>
              <label>{user?.email}</label>
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontFamily={'nunito'} fontWeight={200} color='#747373'>
              <label>{user?.tenant.name}</label>
            </Typography>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Start Date & Time"
                value={formik.values.start_datetime}
                onChange={(datetime: any) => formik.setFieldValue('start_datetime', datetime)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    margin: 'normal',
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.start_datetime && formik.touched.start_datetime),
                    helperText: (
                      <FormHelperText sx={{ minHeight: '1.5em', color: formik.errors.start_datetime ? 'error.main' : 'text.secondary' }}>
                        {formik.touched.start_datetime && formik.errors.start_datetime ? formik.errors.start_datetime : ' '}
                      </FormHelperText>
                    ),
                  },
                }}
              />
              <DateTimePicker
                label="End Date & Time"
                value={formik.values.end_datetime}
                onChange={(datetime: any) => formik.setFieldValue('end_datetime', datetime)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    margin: 'normal',
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.end_datetime && formik.touched.end_datetime),
                    helperText: (
                      <FormHelperText sx={{ minHeight: '1.5em', color: formik.errors.end_datetime ? 'error.main' : 'text.secondary' }}>
                        {formik.touched.end_datetime && formik.errors.end_datetime ? formik.errors.end_datetime : ' '}
                      </FormHelperText>
                    ),
                  },
                }}
              />
            </LocalizationProvider>

            <TextField
              label="Reason"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={3}
              error={Boolean(formik.errors.reason && formik.touched.reason)}
              helperText={
                <FormHelperText sx={{ minHeight: '1.5em', color: formik.errors.reason ? 'error.main' : 'text.secondary' }}>
                  {formik.touched.reason && formik.errors.reason ? formik.errors.reason : ' '}
                </FormHelperText>
              }
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button onClick={handleClose} color="secondary" variant="outlined" style={{ marginRight: '8px' }}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default OutOfOfficeForm;