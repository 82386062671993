import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Route, createRoutesFromElements, Navigate } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import NoLayout from './layouts/NoLayout';
import DefaultLoader from './components/defaultLoader';
import { CalendarPage } from './pages/calendarPage';
import CalendarLoader from './components/calendarComponent/calanderLoader';
import InvestigationDiag from './pages/Clinical_Profile/Investigation_Diagnosis/Investigation_diagnosis.index';
import Test from './pages/test';



// Lazy loading components
const Examination = lazy(() => import('./pages/Clinical_Profile/Examination/Examination'));
const Login = lazy(() => import('./pages/login/index'));
const HPI = lazy(() => import('./pages/Clinical_Profile/HPI/Hpi'));
const RegistrationPage = lazy(() => import('./pages/Registration/index'));
const NewRegistrationPage = lazy(() => import('./pages/Registration/NewRegistration/index'));
const PatientPartnerRegistrationPage = lazy(() => import('./pages/Registration/NewRegistration/PatientPartnerRegistration/index'));
const BillingInformationPage = lazy(() => import('./pages/Registration/NewRegistration/BillingInformation/index'));
const PatinetView = lazy(() => import('./pages/patientView/index'));
const ClinicalPatientList = lazy(() => import('./pages/Clinical_Profile/patinetProfile.index'));
const MedicalHistory = lazy(() => import('./pages/Clinical_Profile/MedicalHistory/medicaHistory.index'));
// Define the routes using createRoutesFromElements
const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to="/login" replace />} />
      {/* Public Route for Login */}
      <Route
        path="/login"
        element={
          <Suspense fallback={<DefaultLoader />}>
            <NoLayout>
              <Login />
            </NoLayout>
          </Suspense>
        }
      />

      {/* Main Routes with Layout */}
      <Route
        path="/"
        element={<MainLayout />} // MainLayout wraps routes with header and sidebar
      >
        {/* Test Routes */}
        <Route
          path="test"
          element={
            <Suspense fallback={<DefaultLoader />}>
              <Test />
            </Suspense>
          }
          handle={{ breadcrumb: 'Test' }}
        >
          <Route
            path="emp/:id"
            element={
              <Suspense fallback={<DefaultLoader />}>
                <HPI />
              </Suspense>
            }
            handle={{ breadcrumb: ({ id }: { id: string }) => `emp ${id}` }}
          />
        </Route>

        {/* Admin Routes */}
        <Route
          path="admin"
          handle={{ breadcrumb: 'Admin' }}
          hasErrorBoundary
        >
          <Route index element={<Navigate to="appointments/" replace />} />

          <Route
            path="appointments/"
            element={
              <Suspense fallback={<CalendarLoader />}>
                <CalendarPage />
              </Suspense>
            }
            handle={{ breadcrumb: 'Appointments' }}
          />
          <Route
            path="registration/"
            handle={{ breadcrumb: 'Registration' }}
          >
            <Route
              index
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <RegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path="patient/:id"
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <PatinetView />
                </Suspense>
              }
              handle={{ breadcrumb: ({ id }: { id: string }) => `Patient #${id}` }}
            />
            <Route
              path="patient-list"
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <RegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path="new-registration"
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <NewRegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'New Registration' }}
            />
            <Route
              path="patient-partner-registration"
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <PatientPartnerRegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'New Registration' }}
            />
            <Route
              path="patient-partner-registration-update"
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <PatientPartnerRegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Update Patient' }}
            />
            <Route
              path="billing-information"
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <BillingInformationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Billing Information' }}
            />
          </Route>

        </Route >
        <Route
          path="clinical"
          handle={{ breadcrumb: 'Clinical' }}
        >
          <Route
            path="profile"
            handle={{ breadcrumb: 'Profile' }}
          >
            <Route
              index
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <ClinicalPatientList />
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path="medical-history"
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <MedicalHistory />
                </Suspense>
              }
              handle={{ breadcrumb: 'Medical History' }}
            />

            <Route
              path="examination"
              element={
                <Suspense >
                  <Examination />
                </Suspense>
              }
              handle={{ breadcrumb: 'examination' }}
            />
            
            <Route
              path="hpi"
              element={
                <Suspense >
                  <HPI />
                </Suspense>
              }
              handle={{ breadcrumb: 'HPI' }}
            />
            <Route
              path="investigation"
              element={
                <Suspense >
                  <InvestigationDiag />
                </Suspense>
              }
              handle={{ breadcrumb: 'Investigation' }}
            />

          </Route>
          
        </Route>
        {/* Add more routes here */}
      </Route>
    </>
  )
);

export default routes;
