import { getRequest, postRequest } from '../../services/httpService';
import { getDiagnosisURL, getDoctorsURL, getPutDiagnosisURL, postDiagnosisURL } from '../../services/urls';
import { PaginatedDiagnosisMasterList } from '../../types';

export type DiagnosisTypeEnum = 'DIFFERENTIAL' | 'FINAL';
export type BlankEnum = '';



export const DiagnosisMenuItems: { value: DiagnosisTypeEnum | BlankEnum; label: string }[] = [
  { value: '', label: 'Select type' },
  { value: 'DIFFERENTIAL', label: 'Differential' },
  { value: 'FINAL', label: 'Final' }
];

export interface PatientDiagnosis {
  id?: number;                   
  patient: number;       
  diagnosis: number;            
  diagnosis_type: DiagnosisTypeEnum; 
  doctor: number;              
  notes?: string;   
}

export enum RoleEnum {
    ADMIN = 'ADMIN',
    DOCTOR = 'DOCTOR',
    NURSE = 'NURSE',
    RECEPTIONIST = 'RECEPTIONIST',
}
  
export interface User {
    id: number; 
    email: string;
    first_name: string;
    last_name: string; 
    username: string;
    gender_name?: string;
    role?: RoleEnum;
}
  
export interface PaginatedUserListList {
    meta: {
      limit: number;
      next: string | null;
      offset: number;
      previous: string | null;
      total_count: number;
    };
    objects: User[];
  }


export function getDiagnosis(query:string):Promise<PaginatedDiagnosisMasterList>{
  return getRequest(getPutDiagnosisURL(query));
}

export function getDoctors():Promise<PaginatedUserListList>{
  return getRequest(getDoctorsURL());
}

export function postDiagnosis(data:PatientDiagnosis):Promise<PatientDiagnosis>{
  return postRequest(postDiagnosisURL(), data);
}

export function getPatientDiagnosis(id:number):Promise<PatientDiagnosis>{
  return getRequest(getDiagnosisURL(id));
}

  