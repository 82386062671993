
export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  return emailRegex.test(email);
};

/**
 * Convert a string to title case.
 * @param {string} str - The input string.
 * @returns {string} - The title cased string.
 */
export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Trim whitespace from both ends of a string.
 * @param {string} str - The input string.
 * @returns {string} - The trimmed string.
 */
export const trimString = (str: string) => {
  return str.trim();
};


/**
 * Check if a string is a valid email format.
 * @param {string} email - The email string to validate.
 * @returns {boolean} - True if valid, false otherwise.
 */
export const isValidEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

/**
 * Checks if the given date is in the past compared to the current date and time.
 * 
 * @param date - The date to check. It can be a valid Date object or a date string.
 * @returns True if the date is in the past, otherwise false.
 * @throws Error if the provided date is invalid.
 */
export const isDateInPast = (date: Date | string): boolean => {
  let parsedDate: Date;

  if (typeof date === 'string') {
    parsedDate = new Date(date);
  } else if (date instanceof Date) {
    parsedDate = date;
  } else {
    throw new Error('Invalid date provided. Must be a Date object or a date string.');
  }

  const now = new Date();
  return parsedDate.getTime() < now.getTime();
};

export const timeDifference = (startTime: string, endTime: string) => {
  const start = new Date(`1970-01-01T${startTime}:00`);
  const end = new Date(`1970-01-01T${endTime}:00`);
  const diffInMinutes = (Number(end) - Number(start)) / (1000 * 60);

  return diffInMinutes;
};
