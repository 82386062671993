import { BasicPatientInfo, loginDetails, PatientListResponse, PatientResponse, Patientv2, ApiResponse, User, File_Source_Req, File_Source_Res, ApiResponseState, AppointmentResponse, AppointmentReasonsResponse, Appointment, AppointmentReasons, DepartmentResponse, UserBasicResponse, PastIllnessResponse, PastIllnessObject, UserBasic } from '../types';
import { getRequest, postRequest, putRequest, uploadFileToS3 } from './httpService';
import { GenderResponse } from '../types';
import type {  CreateInvetigation, PaginatedInvestigationList } from '../types';
import { getUserUrl, postNewRegistrationUrl,addinvestigationURL, getPatientApiUrl, geGenderUrl, loginUserUrl, getLinkPartnerUrl, getDelinkUrl, getPatientApiUrlById, getcountryUrl, getStateUrl, getMyProfileUrl, getUploadURL, getcountrycodeUrl, getDoctorsList,addSexualHistoryURL, addSocialHistoryURL, getSocialHistoryURL, getappointmentsUrl, getappointmentallergysUrl, putappointmentallergysUrl, getappointmentaddictionsUrl, putappointmentaddictionsUrl, putappointmentpreviousinvestigationhistoryUrl, getputpriorinfertilitytestingUrlUrl, putpriorinfertilitytestingUrl, putMainMedicaHistoryURL, getMainMedicalHistoryURL, getfamilyhistoryUrl, getvaccinationUrl, appointments, get_users, get_appointment_reasons, departmetnList, getpastmedicationhistoryUrl, getmedicalhistoryUrl , previousVisitLogs, getWorkingHoursURL, getputurogenitalexaminationURL, getputgeneralsystemexaminationURL, getputinfertilityassessmentURL, getputvitalURL, departmentRooms, operationTheaterTables, get_users_clinic, getputpriorinfertilitytreartmentUrl, status, postpastillnessUrl, getpastillnessUrl, getputpastillnessUrl, getputSurgicalHistoryURL, getLabTestsURL, getLabTestsCatsURL} from './urls';

import { AxiosResponse } from 'axios';

import { useState, useEffect } from 'react';
import { formatDate } from './userService';
import { convertDateToISOWithOffset } from './time';
import { OutOfOfficeRequest } from '../components/calendarComponent/Calendar.helper';

// Purpose: Contains functions that make requests to the API.
export function getUser(name: string): Promise<string> {
  return getRequest(getUserUrl(name), { responseType: 'text' });
}

export function getUserDetailsById(id: number): Promise<UserBasic> {
  return getRequest(`${get_users()}/${id}`);
}

export function getUserById(id: string, clinic: string, department: string) {
  getRequest(`${get_users_clinic(clinic, department)}/${id}`);
}

export function loginUser(loginData: loginDetails): Promise<string> {
  return postRequest<'json'>(loginUserUrl(),loginData);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchPatientList(query: string): Promise<any> {
  const url = getPatientApiUrl();
  const response = await getRequest(`${url}?${query}`, { responseType: 'json' });
  return response;
}

export function getGender(): Promise<GenderResponse> {
  return getRequest(geGenderUrl(), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postPatientPartnerInfo(data:any): Promise<Patientv2> {
  return postRequest<Patientv2>(postNewRegistrationUrl(),data, { responseType: 'json' });
}

export function getLinkPatientList(): Promise<PatientListResponse> {
  return getRequest(getPatientApiUrl(), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postLinkPartner(partnerId: any, data: any): Promise<AxiosResponse<PatientResponse>> {
  return postRequest(getLinkPartnerUrl(partnerId), data, { responseType: 'json' });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postDelink(patientId: any, data: any): Promise<string> {
  return postRequest(getDelinkUrl(patientId), data, { responseType: 'json' });
}

export function getcountryList(): Promise<ApiResponse> {
  return getRequest(getcountryUrl(), { responseType: 'json' });
}

export function getCountryStateList(selectedCountry: string): Promise<ApiResponseState> {
  // Construct the request body according to the API specifications
  const requestBody = JSON.stringify({ country: selectedCountry });

  return postRequest(getStateUrl(), requestBody, { responseType: 'json' });
}


export function getStateList(): Promise<ApiResponse> {
  return postRequest(getStateUrl(), { responseType: 'json' });
}
export function getPatientDetails(id: string): Promise<Patientv2> {
  return getRequest(getPatientApiUrlById(id), { responseType: 'json' });
}

export function getPatientName(id: string): Promise<BasicPatientInfo> {
  return getRequest(getPatientApiUrlById(id), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updatePatient(id: string,data:any): Promise<BasicPatientInfo> {
  return putRequest(getPatientApiUrlById(id),data, { responseType: 'json' });
}


export function getMyProfile(): Promise<User> {
  return getRequest<User>(getMyProfileUrl(), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function uploadFileURL(req: File_Source_Req, file: File):Promise<any> {
  return postRequest<File_Source_Res>(getUploadURL(), req, { responseType: 'text' })
    .then((response: File_Source_Res) => {
      return uploadFileToS3(file, response.presigned_url);
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getcountrycode(): Promise<any> {
  return getRequest(getcountrycodeUrl(), { responseType: 'json' });
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDoctors(name: string = '', limit: number = 10): Promise<any> {
  const url = `${getDoctorsList()}?limit=${limit}&name__icontains=${name}`;
  return getRequest(url, { responseType: 'json' });
}

export function getAppointments(query: string): Promise<AppointmentResponse> {
  return getRequest<AppointmentResponse>(`${getappointmentsUrl()}?${query}`);
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addSexualHistory(id: number,data:any): Promise<any> {
  return putRequest(addSexualHistoryURL(id),data, { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSexualHistory(id: number): Promise<any> {
  return getRequest(addSexualHistoryURL(id), { responseType: 'json' });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSocialHistory(id: number): Promise<any> {
  return getRequest(getSocialHistoryURL(id), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addSocialHistory(id: number,data:any): Promise<any> {
  return putRequest(addSocialHistoryURL(id),data, { responseType: 'json' });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getappointmentsList(tenantId?: string, departmentID?: number): Promise<any> {
  let url = `${getappointmentsUrl()}?limit=1000&order_by=end_datetime`;

  if (tenantId) {
    url += `&clinic_id=${tenantId}`;
  }

  if (departmentID) {
    url += `&department__id=${departmentID}`;
  }

  return getRequest<AppointmentResponse>(url);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getappointmentallergysList(patientId: number): Promise<any> {
  return getRequest(getappointmentallergysUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putappointmentallergysList(patientId: number, allergies: any[]): Promise<any> {
  return putRequest(putappointmentallergysUrl(patientId), allergies);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getappointmentaddictionsList(patientId: number): Promise<any> {
  return getRequest(getappointmentaddictionsUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putappointmentaddictionsList(patientId: number, addiction: any[]): Promise<any> {
  return putRequest(putappointmentaddictionsUrl(patientId), addiction);
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getappointmentpreviousinvestigationhistoryList(patientId: number): Promise<any> {
  return getRequest(putappointmentpreviousinvestigationhistoryUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putappointmentpreviousinvestigationhistoryList(patientId: number, investigationHistory: any): Promise<any> {
  return putRequest(putappointmentpreviousinvestigationhistoryUrl(patientId), investigationHistory);
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getpriorinfertilitytesting(patientId: number): Promise<any> {
  return getRequest(getputpriorinfertilitytestingUrlUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putpriorinfertilitytesting(patientId: number, investigationHistory: any): Promise<any> {
  return putRequest(putpriorinfertilitytestingUrl(patientId), investigationHistory);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getpriorinfertilitytreatment(patientId: number): Promise<any> {
  return getRequest(getputpriorinfertilitytreartmentUrl(patientId));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putpriorinfertilitytratment(patientId: number, investigationHistory: any): Promise<any> {
  return putRequest(getputpriorinfertilitytreartmentUrl(patientId), investigationHistory);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putMainMedicalHistory(patientId: number, data: any): Promise<any> {
  return putRequest(putMainMedicaHistoryURL(patientId), data);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getfamilyhistory(patientId: number): Promise<any> {
  return getRequest(getfamilyhistoryUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putfamilyhistory(patientId: number, investigationHistory: any): Promise<any> {
  return putRequest(getfamilyhistoryUrl(patientId), investigationHistory);
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMainMedicalHistory(patientId: number): Promise<any> {
  return getRequest(getMainMedicalHistoryURL(patientId), { responseType: 'json' });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getvaccination(patientId: number): Promise<any> {
  return getRequest(getvaccinationUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putvaccination(patientId: number, investigationHistory: any): Promise<any> {
  return putRequest(getvaccinationUrl(patientId), investigationHistory);
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getpastmedicationhistory(patientId: number): Promise<any> {
  return getRequest(getpastmedicationhistoryUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putpastmedicationhistory(patientId: number, investigationHistory: any): Promise<any> {
  return putRequest(getpastmedicationhistoryUrl(patientId), investigationHistory);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getmedicalhistory(patientId: number): Promise<any> {
  return getRequest(getmedicalhistoryUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putmedicalhistory(patientId: number, investigationHistory: any): Promise<any> {
  return putRequest(getmedicalhistoryUrl(patientId), investigationHistory);
}


export function departmentsByClinic(clinic__id: string ): Promise<DepartmentResponse> {
  const url = `${departmetnList()}?limit=100&clinic__id=${clinic__id}`;
  return getRequest(url, { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function departments(name: string = '', limit: number = 15, clinicId: any = 33): Promise<any> {
  const url = `${departmetnList()}?limit=${limit}&name__icontains=${name}&clinic__id=${clinicId}`;
  return getRequest(url, { responseType: 'json' });
}

export function appointment_reasons(): Promise<AppointmentReasonsResponse> {
  return getRequest(get_appointment_reasons(), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function userList( limit: number = 10, searchTerm: string = ''): Promise<any> {
  const url = `${get_users()}?limit=${limit}&search=${searchTerm}&order_by=created_on`;
  return getRequest(url, { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateAppointment(id: number, data: any) : Promise<any> {
  const url = `${getappointmentsUrl()}${id}`;
  return putRequest(url, {
    'appointment_date': data.appointment_date,
    'start_datetime': convertDateToISOWithOffset(new Date(`${data.appointment_date} ${data.start_datetime}:00`)),
    'end_datetime': convertDateToISOWithOffset(new Date(`${data.appointment_date} ${data.end_datetime}:00`)),
    'user': data.user,
    'appointment_reason': data.appointment_reason,
    'cancel_or_reschedule_reason': data.cancel_or_reschedule_reason
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cancelAppointment(id: number, data: any) : Promise<any> {
  const url = `${getappointmentsUrl()}${id}/${status()}`;
  return putRequest(url, {
    'status': data.status,
    'remark': data.remark,
    'cancel_or_reschedule_reason': data.cancel_or_reschedule_reason
  });

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postBookAppointment(data:any): Promise<any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return postRequest<any>(appointments(),data, { responseType: 'json' });
}

export async function updateAppointmentDate(id: number, newStartDate: Date, newEndDate: Date) {
  const url = `${getappointmentsUrl()}${id}`;
  const appointment: Appointment = await getRequest(url);
  const reasons: AppointmentReasonsResponse = await getRequest<AppointmentReasonsResponse>(get_appointment_reasons());
  const reasonId = reasons.objects.filter((reason: AppointmentReasons) => reason.reason.toUpperCase() === appointment.appointment_reason__reason.toUpperCase());
  return putRequest(url, {
    'appointment_date': formatDate(newStartDate),
    'start_datetime': convertDateToISOWithOffset(newStartDate),
    'end_datetime': convertDateToISOWithOffset(newEndDate),
    'user': appointment.user.id,
    'appointment_reason': reasonId[0].id,
    'cancel_or_reschedule_reason': 'Availability'
  });
}

export function getAppointmentById(id: number) {
  const url = `${getappointmentsUrl()}${id}`;
  return getRequest<Appointment>(url);
}



export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getVisitLogs(patientId: number | null = null, query: string = ''): Promise<any> {
  const url = `${previousVisitLogs(patientId)}&${query}`;
  return getRequest(url, { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRooms(query = ''): Promise<any> {
  const url = departmentRooms(query);
  return getRequest(url, { responseType: 'json' });
}

export function getWorkingHours(start: string, end: string, query:string = '') {
  return getRequest(`${getWorkingHoursURL()}/?start_date__gte=${start}&end_date__lte=${end}&${query}`);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getgeneralsystemexamination(patientId: number): Promise<any> {
  return getRequest(getputgeneralsystemexaminationURL(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putgeneralsystemexamination(patientId: number, generalsystemexamination: any): Promise<any> {
  return putRequest(getputgeneralsystemexaminationURL(patientId), generalsystemexamination);
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function geturogenitalexamination(patientId: number): Promise<any> {
  return getRequest(getputurogenitalexaminationURL(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function puturogenitalexamination(patientId: number, urogenitalexamination: any): Promise<any> {
  return putRequest(getputurogenitalexaminationURL(patientId), urogenitalexamination);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getinfertilityassessment(patientId: number): Promise<any> {
  return getRequest(getputinfertilityassessmentURL(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putinfertilityassessment(patientId: number, infertilityassessment: any): Promise<any> {
  return putRequest(getputinfertilityassessmentURL(patientId), infertilityassessment);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getvital(patientId: number): Promise<any> {
  return getRequest(getputvitalURL(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putvital(patientId: number, vital: any): Promise<any> {
  return putRequest(getputvitalURL(patientId), vital);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getOTtables(query = ''): Promise<any> {
  const url = operationTheaterTables(query);
  return getRequest(url, { responseType: 'json' });
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateAppointmentStatus(id: number, status: string): Promise<any> {
  const url = `${getappointmentsUrl()}${id}/status`;
  const requestBody = {
    'status': status
  };

  // temp added; after status issue fixed I'll remove it
  // eslint-disable-next-line no-console
  console.log('Request Body:', requestBody);

  return putRequest(url, requestBody);
}

export function getUsersByClinic(id: string, dep: string): Promise<UserBasicResponse> {
  return getRequest(get_users_clinic(id, dep));
}
export function postpastillness(data: PastIllnessObject): Promise<string> {
  return postRequest(postpastillnessUrl(), data, { responseType: 'json' });
}

export function getpastillness(patient_id: number): Promise<PastIllnessResponse> {
  return getRequest(getpastillnessUrl(patient_id)); 
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getputpastillness(id: number): Promise<any> {
  return getRequest(getputpastillnessUrl(id));
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updatePastIllness(id: number, payload: any): Promise<any> {
  return putRequest(getputpastillnessUrl(id), payload);
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSurgicalHistory(patientId: number): Promise<any> {
  return getRequest(getputSurgicalHistoryURL(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putSurgicalHistory(patientId: number, SurgicalHistory: any): Promise<any> {
  return putRequest(getputSurgicalHistoryURL(patientId), SurgicalHistory); 
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLabTests(queryParam:string): Promise<any>{
  return getRequest(getLabTestsURL(queryParam));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLabTestsCats(queryParam:string): Promise<any>{
  return getRequest(getLabTestsCatsURL(queryParam));
}

export function addInvestigation(data:CreateInvetigation):Promise<CreateInvetigation>{
  return postRequest(addinvestigationURL(''), data);
}

export function getInvestigation(query:string):Promise<PaginatedInvestigationList>{
  return getRequest(addinvestigationURL(query));
}
export function bookOutOfOffice(request: OutOfOfficeRequest): Promise<Appointment> {
  return postRequest(getWorkingHoursURL(), request);
}

