/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Grid,ThemeProvider, Box, IconButton } from '@mui/material';
import { theme } from '../../../../../components/Medical_History_Forms/themeprovider';
import HPIBack from '../../../../../assets/SVG/HPIBack.svg';
import EditableTable from '../../../../../components/customTableComponent';
import { ColumnConfig, GroupConfig, TableRowData } from '../../../../../types';
import { getInvestigation } from '../../../../../services/apiService';
import { usePatientId } from '../../../../../components/patientIdcontext';
import { useLoader } from '../../../../../components/loaderProvider/loader';

type OnDataType = (value: boolean) => void; 

const CategoryDetails = ({ onData }: { onData: OnDataType }) => {


  const patientId = usePatientId();
  const { showLoader, hideLoader } = useLoader();

  const onDataRef = useRef<OnDataType | null>(null); // Initialize with null

  useEffect(() => {
    onDataRef.current = onData;
    // eslint-disable-next-line no-console
    console.log(onData);
  }, [onData]);

  
  const onBack = () => {
    onData(false);
  };
 
  const [data, setData] = useState<TableRowData[]>([]);
  const [dataSize, setDataSize] = useState<number>(0);
  const handleOnChange = (id: number, field: string, value: string) => {};


  useEffect(()=>{
    showLoader();
    getInvestigation(`?patient_id=${patientId}`)
      .then((res) => {
        const mappedData = res.objects.map((obj: { 
        id: number; 
        laboratory_test: { identifier: number; name: string }; 
        remark: string; 
      }) => ({
          id: obj.id,
          identifier: obj.laboratory_test.identifier,
          name: obj.laboratory_test.name,
          remark: obj.remark,
        }));
  
        setDataSize(res.meta.total_count);
        setData(mappedData);
        hideLoader();

      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching investigations:', error);
      })
      .finally(() => {
        hideLoader();
        
      });
  
  },[patientId, showLoader, hideLoader]);

  const columnsMale: ColumnConfig[] = [
    { field: 'identifier', headerName: 'Test Code', type: 'text', editable: false },
    { field: 'name', headerName: 'Test Name', type: 'text', editable: false },
    { field: 'remark', headerName: 'Remark', type: 'text', editable: false },
  ];
  const groupConfigMale: GroupConfig[] = [
    { fields: ['identifier'], type: 'column' },
    { fields: ['name'], type: 'column' },
    { fields: ['remark'], type: 'column' },
  ];
  
  return (
    <Box>
      <ThemeProvider theme={theme}>
        <div>
          <Grid container spacing={2} marginTop={2} >
            <Grid item xs={5} display="flex" justifyContent="start">
              <IconButton onClick={onBack}>
                <img src={HPIBack} alt="Back" style={{ width: '2.5rem', height: '2.5rem' }} />
              </IconButton>

            </Grid>

          </Grid>

        </div>

        <Box className='mt-4'>
          <Box className='mt-3'>
            <EditableTable
              columns={columnsMale} 
              data={data} 
              actionType="none"
              // onAction={handleAction}
              onChange={handleOnChange}
              groupConfig={groupConfigMale} 
              dataSize={dataSize}
              // onRowClick={handleAction}
            />
          </Box>
        </Box>

      </ThemeProvider>
    </Box>
  );
};

export default CategoryDetails;